// Configurazioni globali
var app = angular.module('app')
  .config(
    ['$controllerProvider', '$compileProvider', '$filterProvider', '$provide', '$httpProvider',
      function ($controllerProvider: any, $compileProvider: any, $filterProvider: any, $provide: any, $httpProvider: angular.IHttpProvider) {
        // lazy controller, directive and service
        app.controller = $controllerProvider.register;
        app.directive = $compileProvider.directive;
        app.filter = $filterProvider.register;
        app.factory = $provide.factory;
        app.service = $provide.service;
        app.constant = $provide.constant;
        app.value = $provide.value;

        delete $httpProvider.defaults.headers.common['X-Requested-With'];
      }
    ])

  .config(['$qProvider', ($qProvider: any) => {
    $qProvider.errorOnUnhandledRejections(false);
  }])

  .config(['$translateProvider', '$locationProvider', ($translateProvider: angular.translate.ITranslateProvider, $locationProvider: any) => {
    // Register a loader for the static files
    // So, the module will search missing translation tables under the specified urls.
    // Those urls are [prefix][langKey][suffix].
    $translateProvider.useStaticFilesLoader({
      prefix: '../assets/l10n/',
      suffix: '.json'
    });
    // Tell the module what language to use by default
    $translateProvider.preferredLanguage('it');
    // Tell the module to store the language in the local storage
    $translateProvider.useLocalStorage();
    // Fix console strategy warning
    $translateProvider.useSanitizeValueStrategy(null);
    // Qualche libreria inserisce un ! nell'url, con questa istruzione la rimuovo
    $locationProvider.hashPrefix("");
  }]);
